* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");

body{
  background-color: #222222;
  width: 100%;
}

.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.googleAuthBtn{
  display: flex;
  justify-content: center;
}

.g-btn {
  background-color: #ABFAA3;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}

.mb-3 {
  text-align: center;
}

.btn-primary{
  margin-bottom: 10px;
}

#login-page {
  font-family: montserrat;
  background-color: rgb(42, 42, 42);
  display: flex;
  height: 70vh;
  padding-top:15vh;
  padding-bottom: 15vh;
  width: 100vw;
  z-index: -10;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#logo {
  width:30%;
  max-width: 700px;
  min-width: 300px;
  
}

#login-form {
  display: flex;
  flex-direction: column;  
  justify-content: space-evenly;
  align-items: center;
  color:white;
}

#submit-section {
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
}


#submit-section div {
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: center;
  margin: auto;
}

#googleSign {
  align-self: flex-start;
  margin-bottom: 0.5rem;
  margin-top: -1.5rem;
  margin-left: 0.7rem;
}

#googleSign h3{
  font-weight: 500;
}

#submit-section button{
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 30px;
  border-radius: 50%;
  width: 50px;
  border: none;
  height: 50px;
  background-color: #ABFAA3;
  transition: 0.2s ease all;
  box-sizing: border-box;
}

#submit-section button:hover{
  background-color: #222222;
  border: 1px solid #ABFAA3
}

#submit-section button .log-btn{
  transition: 0.2s ease all;
}

#submit-section button:hover .log-btn{
  stroke: #ABFAA3;
}

#submit-section button svg{
  margin-top:10px;
}

#input-fields{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#text-input {
  height: 40px;
  background-color: #22222200;
  font-size: 18px;
  color: #ffffff;
  border: none;
  padding-left:1rem;
  box-shadow: 0px 2px #ffffff;
  padding-right: 1rem;
  transition: 0.4s;
  width: 180px;
}

#text-input:focus{
  outline: none;
  box-shadow: 0px 2px #ABFAA3;
  transition: 0.4s;
}

#input-fields div{
  display: flex;
  flex-direction: row;
  background-color: #22222200;
  margin-bottom: 2rem;
}

#input-fields div svg{
  margin-left: 0.5rem;
  margin-right: 1rem;
}

#switch{
  background-color: #ABFAA3;
  padding-right:2rem;
  padding-left:2rem;
  padding-top:0.7rem;
  padding-bottom:0.7rem;
  font-weight: 600;
  outline:none;
  border: none;
  font-size: 20px;
  margin-top:0.5rem;
  border-radius: 100rem;
  transition: 0.2s ease all;
  box-sizing: border-box;
}

#switch:hover{
  background-color: #222222;
  border: 1px solid #ABFAA3;
  color: #ABFAA3;
  
}

#nav-bar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color:#222222;
}

#nav-bar img{
  width: 130px;
  margin: 5px;
}

#nav-bar a{
  text-decoration: none;
  color: white;
}

#nav-bar svg{
  margin-right:15px;
  padding:10px;
  width:60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: 0.4s ease all;
}

#nav-bar svg .user{
  transition: 0.4s ease all;
  stroke: white;
}
#nav-bar svg:hover .user{
  stroke: #ABFAA3;
  
}

#nav-bar svg:hover {
  border: 1px solid #ABFAA3;
  
}

.subtitle {
  text-align: center;
  background-color: #747474;
}

.subtitle h2 {
  padding: 15px;
  font-size: x-large;
  margin-bottom: 0;
}

.call-to-action{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:50px;
  text-align:  center;
  background-color: #222222
  
}

.call-to-action img{
  width: 300px;
  margin-top:-60px;
}

.text{
  margin-bottom: 50px;
}

.call-to-action .text h1{
  color: #ABFAA3;
  margin-bottom: 10px;
}

.call-to-action .text h4{
  font-size: 16px;
  font-weight: 600;
  color: white
}

.call-to-action a{
  background-color: #ABFAA3;
  text-decoration: none;
  color: #222222;
  margin: 20px;
  font-weight: 700;
  font-size:20px;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-right: 32px;
  padding-left: 32px;
  border-radius: 30px;
  box-shadow: 0px 2px 6px #22222236;
  transition: 0.24s ease all;
  text-align: center;
  
}

.call-to-action a:hover{
  background-color: #222222;
  border: 1px solid #ABFAA3;
  color: #ABFAA3;
  box-sizing: border-box;
}

.event-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 20px;
  background-color: #222222;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #222222b2;
  overflow: hidden;
}

.event-holder img{
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 20px;
}

.event-holder p{
  margin-top:5px;
  font-size: 12px;
  line-height: 12px;
} 

.event-holder{
  width: 350px;
  height: 350px;
  
}

.event-holder img{
  width: 300px;
  height: 150px;
}

.dashAttend button{
  background-color: #ABFAA3;
  text-decoration: none;
  color: #222222;
  font-weight: 700;
  margin-top:10px;
  font-size:12px;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 30px;
  box-shadow: 0px 2px 6px #22222236;
  transition: 0.2s ease all;
  text-align: center;
  box-sizing: border-box;
  border-color: #ABFAA3;
}

.dashAttend button:hover{
  background-color: #222222;
  border: 1px solid #ABFAA3;
  color: #ABFAA3;
}

.event-holder a{
  background-color: #ABFAA3;
  text-decoration: none;
  color: #222222;
  font-weight: 700;
  margin-top:10px;
  font-size:12px;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 30px;
  box-shadow: 0px 2px 6px #22222236;
  transition: 0.2s ease all;
  text-align: center;
  box-sizing: border-box;
}

.event-holder a:hover{
  background-color: #222222;
  border: 1px solid #ABFAA3;
  color: #ABFAA3;
}

.events-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: white;
}

.events-container2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: white;
  min-height: calc(100vh - 328px) !important;
}

.event-holder a{
    padding-right:100px;
    padding-left:100px;
}

.event-holder button{
    padding-right:100px;
    padding-left:100px;
}

.event-holder h3{
  font-size:14px !important;
}

@media only screen and (min-width: 800px) {

  .call-to-action .text h1{
    font-size: 42px;
  }

  .call-to-action .text h4{
    font-size: 32px;
  }


}

.contact{
  position:relative;
  width: 100%;
  color: white;
  background-color: #222222;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.contact p{
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  margin: 0 !important;
}

.contact a{
  color: #ABFAA3;
  padding: 15px;
}

.contact a:hover{
  color: rgb(83, 156, 75);
  transition: 0.2s ease all;
}

.leave{
  position: absolute;
  top: 40px;
  right: 20px;
  padding:10px;
  border-radius: 50%;

}

.leave path{
  stroke: white;
  transition: 0.2s ease all;
}

.leave:hover path{
  stroke: #ABFAA3;
}

.leave:hover {
  background-color:#222222;
}

.call-to-action-report{
  text-align: center;
  padding:40px;
}

.call-to-action-report{
  color: rgb(120, 228, 108);
}

input[type="file"]{
  display: none;
}

.imgInputField{
  background-color: #ABFAA3;
  display: flex;
  justify-content: center;
  color: #222222;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  width: 80vw;
  height: 80vw;
  max-width: 300px;
  max-height: 300px;
  border-radius: 20px;
  border: 3px solid #222222; 
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}

form {
  display: flex;
  align-items:center; 
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 100px;
}

.location {
  display: flex;
  align-items:center; 
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

Button[type="submit"]{
  background-color: #ABFAA3;
  border: none;
  text-decoration: none;
  color: #222222;
  font-weight: 700;
  font-size:24px;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
  border-radius: 30px;
  box-shadow: 0px 2px 6px #22222236;
  transition: 0.2s ease all;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-top:50px;
}

Button[type="submit"]:hover{
  background-color: white;
  color: #222222;
}

.btn-primary:hover{
  background-color: #222222;
  color: white;
}

#upload{
  margin-bottom: 150px;
  background-color: #ABFAA3;
  border: none;
  text-decoration: none;
  color: #222222;
  font-weight: 700;
  font-size:24px;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
  border-radius: 30px;
  box-shadow: 0px 2px 6px #22222236;
  transition: 0.2s ease all;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-top:50px;
}

#upload:hover{
  background-color: white;
  color: #222222;
}

.location h2{
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
}

form h2{
  color: white;
  margin-top: 50px;
  margin-bottom: 20px;
}

.card {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.438);
  display: flex;
  justify-content: center;
  align-items:center; 
  z-index: 100;
}

.card-text {
  background-color: #222222;
  width: 80vw;
  border-radius: 20px;
  z-index: 110;
}

.card img{
  width: 70vw;
  height: 40vh;
  object-fit: cover;
  border-radius: 20px;
  margin-left:5vw;
  margin-top:5vh;
}

.card h3{
  color: #ABFAA3;
  margin-left:40px;
  margin-top: 10px;
}

.card p{
  margin-left: 40px;
  color:white;
  margin-bottom:30px;
}


.checked {
  background-color: rgb(146, 202, 255);
  color: rgba(0,0,0,0);
  border: 2px solid rgb(112, 191, 255);
  transition: 0.4ms ease all;
}

.map {
  display: block;
  width: 80vw;
  height: 80vw;
  max-width: 600px;
  max-height: 600px;
  border-radius: 20px;
  background-color: white;
  overflow: hidden;
}

.mapSub{
  width: auto !important;
}

.info-input{
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  color: white;
  
}

.input-box{
  padding-top:8px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 8px;
  font-size: 24px;
  border: none;
}

.map div{
  width: auto;
}

.report-sec{
  margin-bottom:-100px;
  background-color: #222222
}

.cardsDash{
  width:70%;
  background-color: #222222;
}

.dashAttend{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashTitle{
  color: white;
  font-weight: 700;
  font-size:24px;
}

.logOutBox{
  display: flex;
  justify-content: center;
}

.logOutBox button{
  width: 200px
}

.captureSec{
  text-align: center;
  color: #ABFAA3;
  background-color: #222222;
  margin-top:60px;
  margin-bottom: 0px !important;
  padding-bottom: 100px;
}

.captureSec p{
  color: white;
}

.capSec2{
  background-color: #222222;
  margin-top:-60px;
}

.capSec2 h1{
  padding: 40px;
}

.sub-Capture{
  background-color: #ABFAA3 !important;
  border: none !important;
  text-decoration: none;
  color: #222222 !important;
  font-weight: 700 !important;
  font-size:24px !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  padding-right: 24px !important;
  padding-left: 24px !important;
  border-radius: 30px !important;
  box-shadow: 0px 2px 6px #22222236 !important;
  transition: 0.2s ease all !important;
  text-align: center;
  box-sizing: border-box !important;
  cursor: pointer;
  margin-top:50px;
}

.sub-Capture:hover{
  background-color: white !important;
  color: #222222 !important;
}

.sub-CaptureSecond{
  margin-bottom: 50px !important;
}

.reportSub{
  margin-bottom: 100px;
}


#cont{
  min-height: calc(100vh - 75px - 57px);
  background-color: #222222;
}

.row {
  margin:0 !important;
}

.col {
  padding:0 !important;
  width:100% !important; 
}

@media only screen and (min-width: 1200px){
  .box{
    width:25%;
  }
}

.box{
  background-color: #222222;
  color: white;
  border:none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#dashboard{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4b4b4b;
}

.dashCenter{
  background-color: #4b4b4b;
}

#login{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222222;
}

#signup{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agreements{
  background-color:white;
  padding: 40px;
}

#root{
  width: 100%;
}

a[href="/login"]{
  color: #ABFAA3;
}

a[href = "/login"]:hover{
  color:rgb(83, 156, 75);
}

a[href="/signup"]{
  color: #ABFAA3;
}

a[href = "/signup"]:hover{
  color:rgb(83, 156, 75);
}

#formBasicEmail{
  color: white;
  background-color: #222222;
  border: none;
  box-shadow: 0px 3px white;
  border-radius: 0px;
  padding-bottom:10px;
  margin-bottom: 30px;
  margin-top:20px;
  padding-left:5px;
  margin-left: 10px;
}

#formBasicEmail:focus{
  box-shadow: 0px 3px #ABFAA3;
  transition: 500ms ease all;
}

#formBasicPassword{
  color: white;
  background-color: #222222;
  border: none;
  box-shadow: 0px 3px white;
  border-radius: 0px;
  padding-bottom:10px;
  padding-left:5px;
  margin-left: 10px;
}

#formBasicPassword:focus{
  box-shadow: 0px 3px #ABFAA3;
  transition: 500ms ease all;
}

.usernameFeild{
  display: flex;
  flex-direction: row;
}

.pass{
  display: flex;
  flex-direction: row;
}

.modal-footer .btn-primary{
  background-color: #222222;
  border:none;
  transition: 0.5s ease all;
}

.modal-footer .btn-primary:hover{
  background-color: #ABFAA3;
  color:#222222;
}

.logOutBox .btn-primary{
  background-color: #ABFAA3;
  border:1px solid #ABFAA3;
  transition: 0.5s ease all;
  color: #222222;
  font-weight: 700;
  border-radius: 20px;
}

.logOutBox .btn-primary:hover{
  color: #ABFAA3;
  background-color:#222222;
  transition: 0.5s ease all;
  border:1px solid #ABFAA3;
}